import { reactive, toRefs, watch } from 'vue';
import { Passenger, TicketBooking } from "@/models/order_modes";
import { PassengerTypeQty, PaymentType } from "@/models/collection_models";
import { FareFamily, FareFamilyData, FareFamilyOutput } from "@/models/offer_models";
import { RequestData } from "@/components/requestId/SearchResult";
const { requestId } = RequestData
const state = reactive({
  loading: false,
  loadingMessage: '',
  requestid: requestId.value as string,
  selectedTariff: {} as FareFamily | null,
  tariffs: {} as FareFamilyOutput,
  ticketBooking: {} as TicketBooking,
  passengers: {} as Passenger[],
});

export default function flightBookingController() {

  watch(() => state.tariffs, (before, after) => {
    if (state.tariffs && state.tariffs.data) {
      for (const tariff of state.tariffs.data.fareFamily) {
        if (tariff.segments != state.selectedTariff?.segments)
          tariff.isSelected = false;
      }
    }
  }, { deep: true },);

  const generatePassengers = (passengerTypeQty: PassengerTypeQty[]) => {
    state.passengers = [];
    for (const typeQty of passengerTypeQty.slice().sort((a, b) => a.orderNumber > b.orderNumber ? 1 : -1)) {
      const qty: number = typeQty.qty;
      for (let i = 0; i < typeQty.qty; i++) {
        state.passengers.push({
          type: typeQty.type, phoneNumberInfo: {
            code: "",
            name: "",
            codeRus: "",
            citizenshipCode: "",
            phone: "",
            locale: "",
            isDefault: true,
          },
          phoneNumber: "",
          email: "",
          gender: {} as any,
          lastName: "",
          firstName: "",
          middleName: "",
          birthDate: "",
          citizenship: {
            code: "",
            name: "",
            codeRus: "",
            citizenshipCode: "",
            phone: "",
            locale: "",
            isDefault: true,
          },
          document: {
            type: {
              id: 0,
              code: "",
              name: "",
              isDefault: true,
              orderNumber: 0,              
            },
            number: "",
            issue: "",
            expire: "",
            regionId:""
          },
        });
      }
    }
  };

  return {
    state: toRefs(state),
    generatePassengers,
  };
}
